<template>
  <div class="relative">
    <Swiper
      :modules="[SwiperNavigation]"
      :slides-per-view="props.itemsPerRowOnMobile"
      :space-between="15"
      :slides-offset-before="25"
      class="pb-20"
      :slides-offset-after="25"
      :scrollbar="{ el: '.swiper-scrollbar', draggable: true }"
      :breakpoints="{
        '768': {
          slidesPerView: 3,
          spaceBetween: 20,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
          slidesPerGroup: 4.4,
          slidesOffsetBefore: 0,
        },
        '1024': {
          slidesPerView: props.itemsPerRowOnDesktop,
          spaceBetween: 20,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false, // !!(props.alignment === 'center'),
          slidesPerGroup: 1,
          slidesOffsetBefore: 0,
        },
      }"
    >
      <SwiperSlide v-for="item in items" :key="item.id" class="swiper-slide">
        <slot :item="item" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" />

      <!-- <div class="swiper-scrollbar"></div> -->
    </Swiper>
    <!-- If we need navigation buttons -->
    <template v-if="arrows">
      <button
        v-if="items.length > itemsPerRowOnDesktop"
        class="swiper-nav swiper-button-prev"
        :class="{
          'swiper-nav__inverted': inverted,
        }"
        aria-label="previous slide"
        @click="prevSlide"
      >
        <svg-icon-chevron-left class="w-8 h-8" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
      </button>
      <button
        v-if="items.length > itemsPerRowOnDesktop"
        class="swiper-nav swiper-button-next"
        :class="{
          'swiper-nav__inverted': inverted,
        }"
        aria-label="next slide"
        @click="nextSlide"
      >
        <svg-icon-chevron-left class="w-8 h-8" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<ReturnType<typeof mapProductListing>[]>,
    required: true,
  },
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 5,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.4,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});

const currentIndex = ref(1);

function nextSlide() {
  currentIndex.value = currentIndex.value + 1;
}

function prevSlide() {
  currentIndex.value = currentIndex.value - 1;
}
</script>

<style lang="postcss" scoped>
.swiper,
.swiper-container {
  width: 100%;
  height: fit-content;
}

.swiper-nav {
  @apply hidden lg:flex;
  width: 40px;
  height: 40px;
  &::after {
    content: unset;
  }
  svg {
    @apply text-gray-200;
    height: 26px;
  }

  &__inverted {
    @apply border-white;

    svg {
      @apply text-white;
    }
  }

  &.swiper-button-next {
    transform: scaleX(-1);
    right: -48px;
  }

  &.swiper-button-prev {
    left: -48px;
  }

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 3px;
  left: 5%;
  z-index: 50;
  height: 2px;
  width: 98%;

  @media (min-width: 1024px) {
    width: 100%;
    left: 0;
  }
}

.swiper-nav {
  @apply hidden lg:flex;
  width: 35px;
  height: 35px;

  &::after {
    content: unset;
  }

  &__inverted {
    svg {
      @apply text-black;
    }
  }

  &.swiper-button-next {
    right: -70px;
  }

  &.swiper-button-prev {
    left: -70px;
  }
  &.swiper-button-next,
  &.swiper-button-prev {
    top: 40%;
  }

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}
</style>
<style>
.swiper-scrollbar-drag {
  background: black !important;
}

.swiper-slide,
swiper-slide {
  height: auto;
}
</style>
